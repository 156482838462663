html {
  height: 100%;
  margin:0;
  padding:0;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
  font-family: 'Love Ya Like A Sister', cursive;
  /* height: 100%; */
}

#root {
  height: 100%;
}

.body-wrapper {
  overflow: scroll;
  margin: 0;
  padding: 0;
  background-color: rgb(233, 161, 185);
  height: 100%;
  zoom: 1;
  transition-duration: 0s;
  background-image: url(/grain.png);
  background-position: center 0;
  transition: background-color 0.3s ease-out;
}

.center-center-wrapper {
  display: flex;
  align-items: center;
  min-height: 100%;
  justify-content: center;

  flex-direction: column;
}

.statement-wrapper {
  /* margin: 0 auto;
  text-align: center; */
  flex: none;
  /* max-width: 70%; */
  max-width: 70%;
  margin: 0 auto;
  text-align: center;
  z-index: 2;
  visibility: hidden;


  /* Animation */
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.statement-wrapper.active {
  opacity: 1;
  visibility: visible;
}

.sr-bullets {

}

.sr-bullet-wrapper {
  width: 35px;
  height: 35px;

  position: relative;
  display: inline-block;
  zoom: 1;
  overflow: hidden;
  cursor: pointer;
}

.sr-bullet {
  width: 35px;
  height: 70px;
  background-image: url(/bullet-5.png);
  background-size: 35px 70px;

  position: relative;
  top: -100%;
}

.sr-bullet-active {
  top: 0%;
}

.main-title {
  font-size: 100px;
  color: #fff;
}

.sub-title {
  font-size: 36px;
  color: #605f5f;
}

.bg-pink {
  background-color: rgb(233, 161, 185);
}

.bg-blue {
  background-color: rgb(2, 201, 201);
}

.bg-yellow {
  background-color: rgb(255, 195, 0);
}

.bg-black {
  background-color: rgb(51, 51, 51);
}

.bg-grey {
  background-color: rgb(220, 220, 220);
}

.bg-green {
  background-color: rgb(194, 213, 63);
}


/* @media screen and (max-width: 767px) {
  .polaroids li:nth-child(n+5) {
    display: none;
  }
} */

.polaroids-wrapper {
  /* position: absolute;  */
  margin: 40px 0;
  z-index: 1;
  text-align: center;
}

.single-polaroid {
  /* Animation */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in;
}

.single-polaroid.active {
  opacity: 1;
  visibility: visible;
}

  ul.polaroids {
    margin: 0;
    padding: 0;
  }
  ul.polaroids li { display: inline-block; }
  ul.polaroids a { 
    background: #fff; 
    display: inline-block; 
    /* float: left;  */
    margin: 0 0 0 100px; 
    width: auto; 
    padding: 10px 10px 15px; 
    text-align: center; 
    font-family: "Marker Felt", sans-serif; 
    text-decoration: none; 
    color: #333; 
    font-size: 18px; 
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25); 
    -moz-box-shadow: 0 3px 6px rgba(0,0,0,.25); 
    -webkit-transition: -webkit-transform .15s linear; 

    min-width: 120px;
  }
  ul.polaroids li {
    padding-left: 0;
  }
  /* @media screen and (max-width: 768px) {
    ul.polaroids li a {
      margin-left: 15px;
    } 
  } */

  ul.polaroids a > img { 
    display: block; 
    /* width: 180px;  */
    margin: 0 auto 12px auto;

    outline: none; 

    width: auto;
    height: auto;
    max-width: 220px;
    /* min-width: 120px; */
    max-height: 150px;
    
  }
  ul.polaroids a:after { content: attr(title); }

  ul.polaroids li:nth-child(1) a { 
    margin: 0 0 0 0; 
  }
  /* ul.polaroids li:nth-child(even) a { 
    -webkit-transform: rotate(2deg);  
    -moz-transform: rotate(2deg); 
  }
  ul.polaroids li:nth-child(3n) a { 
    -webkit-transform: none; 
    position: relative; 
    top: -5px;  
    -moz-transform: none; 
  }
  ul.polaroids li:nth-child(5n) a { 
    -webkit-transform: rotate(5deg); 
    position: relative; right: 5px;  
    -moz-transform: rotate(5deg); 
  }
  ul.polaroids li:nth-child(8n) a { 
    position: relative; 
    right: 5px; 
    top: 8px; 
  }
  ul.polaroids li:nth-child(11n) a { 
    position: relative; 
    left: -5px; 
    top: 3px; 
  } */
  
  ul.polaroids li.messy a {
    margin-top: -400px;
    position: relative;
    left: 160px;
  }
  
  ul.polaroids li a:hover { 
    -webkit-transform: scale(1.25); 
    -moz-transform: scale(1.25); 
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.5); 
    -moz-box-shadow: 0 3px 6px rgba(0,0,0,.5); 
    position: relative; 
    z-index: 5; 
  }

  /* ul.polaroids li a:active { 
    background-color: yellow;
  }

  ul.polaroids li a:focus { 
    background-color: red;
  } */

  /* .example-enter {
    opacity: 0.01;
  }
  
  .example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  
  .example-leave {
    opacity: 1;
  }
  
  .example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  } */


  /* .example-enter {
    opacity: 0.01;
    width: 0px;
  }
  
  .example-enter.example-enter-active {
    opacity: 1;
    width: 100px;
    transition: 700ms;
  }
  
  .example-leave {
    opacity: 1;
    width: 100px;
  }
  
  .example-leave.example-leave-active {
    opacity: 0.01;
    width: 0px;
    transition: 700ms;
  } */


  .heart-image {
    height: 50px;
    width: 50px;
  }

  /* Responsivenes */

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1200px) {
    .main-title {
      font-size: 80px;
    }
    .sub-title {
      font-size: 28px;
    }

    .heart-image {
      height: 30px;
      width: 30px;
    }

    .polaroids-wrapper {
      margin: 30px 0;
    }

    ul.polaroids li a {
      margin-left: 30px;
      padding: 10px 10px 15px;

      min-width: 100px;
    }

    ul.polaroids a > img {
      /* min-width: 100px; */
      max-width: 120px;
      max-height: 120px;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 992px) {

  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {

  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .main-title {
      font-size: 40px;
    }
    .sub-title {
      font-size: 20px;
    }
    .statement-wrapper {
      max-width: 90%;
    }

    .heart-image {
      height: 25px;
      width: 25px;
    }

    .sr-bullet-wrapper {
      width: 20px;
      height: 25px;
    }

    .sr-bullet {
      background-size: 25px 50px;
      width: 25px;
      height: 50px;
    }

    .polaroids-wrapper {
      margin: 15px 0;
    }

    ul.polaroids li a {
      margin-left: 15px;
      padding: 5px 5px 5px;

      min-width: 60px;
    }

    ul.polaroids a > img {

      max-width: 80px;
      max-height: 80px;
    }
  }

  /* Custom, iPhone Retina */ 
  @media only screen and (max-width : 320px) {
    .main-title {
      font-size: 36px;
    }
    .sub-title {
      font-size: 18px;
    }
    .statement-wrapper {
      max-width: 90%;
    }
  }


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

